import * as React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/Seo'
import { useContent } from '../hooks/useContent'

import OurGames from '../components/Landing/OurGames'

const LandingPage = ({ data }) => {
  const content = useContent(data)

  if (typeof window !== 'undefined') {
    const siteUrl = window.location.search
    const urlParams = new URLSearchParams(siteUrl)
    const redirect_uri = urlParams.get('redirect_uri')
    if (redirect_uri) {
      setTimeout(() => {
        window.location = redirect_uri
      }, 1500)
    }
  }

  return (
    <>
      <Seo />

      <OurGames componentData={content?.popularGames} />
    </>
  )
}

export const query = graphql`
  query ($content: String = "mainPage", $careerList: String = "career") {
    allMarkdownRemark(filter: { frontmatter: { content: { eq: $content } } }) {
      edges {
        node {
          id
          frontmatter {
            hero {
              heading
              subHeading
              button {
                text
                link
              }
            }
            hyperCasual {
              heading
              subHeading
              items {
                title
                description
                imageName
              }
            }
            popularGames {
              heading
              subHeading
              viewAll
            }
            weAreHiring {
              heading
              subHeading
              viewAll
            }
            blog {
              heading
              viewAll
            }
          }
        }
      }
    }

    careers: allMarkdownRemark(
      filter: { frontmatter: { content: { eq: $careerList } } }
      limit: 2
      sort: { fields: frontmatter___updateDate, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            heading
            summary
            slug
            infos {
              location {
                label
                value
              }
            }
          }
        }
      }
    }
  }
`

export default LandingPage

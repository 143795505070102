import React from 'react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import Badge from './Badge'

const CardGame = ({
  name,
  summary,
  appStoreUrl,
  googlePlayUrl,
  numberOfDownload,
  slogan,
  thumbnail,
  noBorder,
  isHome = false,
}) => {
  return (
    <div
      className={classNames(
        'relative flex flex-col gap-4 rounded-xl border-2	border-solid border-gray-dark bg-white mt-10',
        { 'h-[calc(100%_-_40px)]': isHome },
        { '!border-0': noBorder }
      )}
    >
      {numberOfDownload && <Badge numberOfDownload={numberOfDownload} />}
      {slogan && <Badge slogan={slogan} />}
      {typeof thumbnail === 'string' && String(thumbnail).startsWith('http') ? (
        <div>
          <img
            src={thumbnail}
            alt="thumbnail of game"
            width={120}
            height={120}
          />
        </div>
      ) : (
        <div>
          <GatsbyImage loading='eager' image={thumbnail} alt={name} />
        </div>
      )}
      <h3 className="typo-medium-16 md:typo-medium-20 text-marine">{name}</h3>
      <p className="typo-medium-14 md:typo-medium-16 text-gray">{summary}</p>
      <div className="w-full flex justify-between items-center mt-auto gap-5">
        <a href={appStoreUrl} className="text-right">
          <img
            src="../images/buttons/icon-app-store.inline.svg"
            alt="app store"
            placeholder="none"
            quality={100}
            width={144}
            height={48}
            loading='eager'
          />
        </a>
        <a href={googlePlayUrl}>
          <img
            src="../images/buttons/icon-google-play-store.inline.svg"
            alt="google play"
            placeholder="none"
            quality={100}
            width={144}
            height={48}
            loading='eager'
          />
        </a>
      </div>
    </div>
  )
}

export default CardGame

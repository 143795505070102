import React from 'react'

import { graphql, Link, useStaticQuery } from 'gatsby'
import { nanoid } from 'nanoid'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'

import Container from '../../Common/Container'
import CardGame from '../../Common/Card/CardGameLanding'

import { useContent } from '../../../hooks/useContent'
import { getThumbnail } from '../../../helpers/thumbnail'

import RightChevron from '../../../assets/images/icons/svg/icon-right-chevron-color.svg'

import { Pagination } from 'swiper'

const OurGames = ({ componentData }) => {
  const data = useStaticQuery(graphql`
    query ($content: String = "game") {
      games: allMarkdownRemark(
        filter: {
          frontmatter: { content: { eq: $content }, showIndex: { eq: true } }
        }
        sort: { order: ASC, fields: frontmatter___index }
      ) {
        edges {
          node {
            frontmatter {
              name
              summary
              thumbnail
              googlePlayUrl
              appStoreUrl
              numberOfDownload
              slogan
              index
            }
          }
        }
      }
      images: allFile(filter: { relativeDirectory: { eq: "images/games" } }) {
        edges {
          node {
            relativePath
            name
            relativeDirectory
            childImageSharp {
              gatsbyImageData(
                width: 120
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
                breakpoints: [640, 768, 1024, 1280]
              )
            }
          }
        }
      }
    }
  `)

  const content = useContent(data, 'games')

  return (
    <>
      <Container.FullWidth className="!bg-white">
        <div className="w-full pt-10 pb-10 lg:pt-20">
          <div className="flex flex-col items-center justify-center">
            <div className="text-center md:text-left">
              <p className="typo-medium-14 lg:typo-medium-24 text-gray">
                {componentData.subHeading}
              </p>
            </div>

            <div className="pt-8">
              <Link
                to="/games"
                className="group basis-1/4 flex justify-end items-center space-x-3 hover:underline transition-all hover:cursor-pointer"
              >
                <p className="text-[16px] font-bold font-sans text-secondary">
                  {componentData.viewAll}
                </p>
                <span className="group-hover:translate-x-3 transition-transform">
                  <RightChevron />
                </span>
              </Link>
            </div>
          </div>
        </div>

        <div className="w-full px-5 sm:px-0 lg:px-5 pb-10 lg:pb-20 pt-8 ourgames-section">
          <div className="w-full overflow-hidden">
            <div className="">
              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                autoplay={{ delay: 5000 }}
                centeredSlides={false}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  1280: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                    centeredSlides: false,
                    pagination: false,
                  },
                }}
                modules={[Pagination, Autoplay]}
                grabCursor={true}
                className="mySwiper"
              >
                {content?.games.map(
                  ({
                    name,
                    summary,
                    googlePlayUrl,
                    appStoreUrl,
                    thumbnail,
                    numberOfDownload,
                    slogan,
                  }) => (
                    <SwiperSlide
                      key={nanoid()}
                      className="ourgames-slider"
                      style={{ zIndex: -1 }}
                    >
                      <div className="px-0 sm:px-5 lg:px-0 lg:pl-3 lg:pr-7">
                        <CardGame
                          name={name}
                          summary={summary}
                          appStoreUrl={appStoreUrl}
                          googlePlayUrl={googlePlayUrl}
                          numberOfDownload={numberOfDownload}
                          slogan={slogan}
                          noBorder
                          thumbnail={getThumbnail(content, thumbnail)}
                          isHome={true}
                        />
                      </div>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
          </div>
        </div>
      </Container.FullWidth>
    </>
  )
}

export default OurGames
